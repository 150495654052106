var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { $$activeContact } from '@/entities/contact/model';
import { communication } from '@/shared/api';
import { $$activeStrategy, $$alerts, $$lastContact } from '@/shared/effector';
import { $$contactCustomStrategyStatuses } from '@/shared/effector/contact';
import { RequestFactory } from '@/shared/factories';
import { combine, createEvent, createStore, sample } from 'effector';
import { every, reset } from 'patronum';
//* Info For Form
var $contact = $$activeContact.$item;
var $contactCustomStrategyStatus = combine($$contactCustomStrategyStatuses.$items, $contact, function (items, contact) {
    return contact ? items.find(function (item) { return item.contact_id.contact_id === (contact === null || contact === void 0 ? void 0 : contact.contact_id); }) : null;
});
var $nextStep = combine($$activeStrategy.$steps, $contactCustomStrategyStatus, function (steps, status) {
    return status
        ? steps.find(function (step) {
            return step.custom_strategy_step_id ===
                status.custom_strategy_status_id.custom_strategy_next_step_id;
        })
        : null;
});
var $nextStepResults = combine($$activeStrategy.$stepResults, $nextStep, function (stepResults, step) {
    return step
        ? stepResults.filter(function (stepResult) { return stepResult.custom_strategy_step_id === step.custom_strategy_step_id; })
        : [];
});
var $infoPending = every({
    stores: [
        $$activeContact.$status,
        $$activeStrategy.$stepsStatus,
        $$activeStrategy.$stepResultsStatus,
    ],
    predicate: function (status) { return status === 'pending'; },
});
//* Form
var $stepResultId = createStore(null);
var $communicationTypeId = createStore(null);
var $comment = createStore('');
var $formCanSubmit = combine($stepResultId, $communicationTypeId, $comment, function (stepResultId, communicationTypeId, comment) {
    return !!stepResultId && !!communicationTypeId && !!comment;
});
var stepResultIdChanged = createEvent();
var communicationTypeIdChanged = createEvent();
var commentChanged = createEvent();
var formReseted = createEvent();
$stepResultId.on(stepResultIdChanged, function (_, value) { return value; });
$communicationTypeId.on(communicationTypeIdChanged, function (_, value) { return value; });
$comment.on(commentChanged, function (_, value) { return value; });
reset({
    clock: formReseted,
    target: [$stepResultId, $communicationTypeId, $comment],
});
//* Create Communication
var createCommunication = RequestFactory({
    name: 'CREATE_COMMUNICATION',
    api: communication.create,
});
var submited = createEvent();
sample({
    clock: submited,
    source: {
        contact: $contact,
        step: $nextStep,
        stepResultId: $stepResultId,
        communicationTypeId: $communicationTypeId,
        comment: $comment,
        infoPending: $infoPending,
        formCanSubmit: $formCanSubmit,
    },
    filter: function (_a) {
        var infoPending = _a.infoPending, formCanSubmit = _a.formCanSubmit;
        return !infoPending && formCanSubmit;
    },
    fn: function (_a) {
        var contact = _a.contact, step = _a.step, stepResultId = _a.stepResultId, communicationTypeId = _a.communicationTypeId, comment = _a.comment;
        return ({
            contact_id: contact.contact_id,
            custom_strategy_step_id: step.custom_strategy_step_id,
            custom_strategy_step_result_id: stepResultId,
            communication_type_id: communicationTypeId,
            comment: comment,
            date: new Date().toISOString(),
        });
    },
    target: createCommunication.submited,
});
sample({
    clock: createCommunication.done,
    source: $contact,
    fn: function (contact) { return contact.contact_id; },
    target: $$contactCustomStrategyStatuses.oneUpdated,
});
sample({
    clock: createCommunication.done,
    target: $$lastContact.submited,
});
sample({
    clock: createCommunication.done,
    fn: function () { return 'Информация сохранена!'; },
    target: $$alerts.showSuccess,
});
export var $$contactCommunication = __assign(__assign({ 
    // Info
    info: {
        $contact: $contact,
        $contactCustomStrategyStatus: $contactCustomStrategyStatus,
        $nextStep: $nextStep,
        $nextStepResults: $nextStepResults,
        $infoPending: $infoPending,
    }, 
    // Form
    form: {
        $stepResultId: $stepResultId,
        $communicationTypeId: $communicationTypeId,
        $comment: $comment,
        $canSubmit: $formCanSubmit,
        stepResultIdChanged: stepResultIdChanged,
        communicationTypeIdChanged: communicationTypeIdChanged,
        commentChanged: commentChanged,
        reseted: formReseted,
    } }, createCommunication), { submited: submited });
