import { landing } from '@/shared/api';
import { $$alerts, $$myLanding } from '@/shared/effector';
import { attach, createEvent, createStore, sample } from 'effector';
import { spread } from 'patronum';
var updateFx = attach({ effect: landing.update });
var $phone = createStore('');
var $vk = createStore('');
var $instagram = createStore('');
var $telegram = createStore('');
var $youtube = createStore('');
var $about = createStore('');
var $youtubeVideo = createStore('');
var $pending = updateFx.pending;
var phoneChanged = createEvent();
var vkChanged = createEvent();
var instagramChanged = createEvent();
var telegramChanged = createEvent();
var youtubeChanged = createEvent();
var aboutChanged = createEvent();
var youtubeVideoChanged = createEvent();
var submited = createEvent();
var reseted = createEvent();
$phone.on(phoneChanged, function (_, value) { return value; });
$vk.on(vkChanged, function (_, value) { return value; });
$instagram.on(instagramChanged, function (_, value) { return value; });
$telegram.on(telegramChanged, function (_, value) { return value; });
$youtube.on(youtubeChanged, function (_, value) { return value; });
$about.on(aboutChanged, function (_, value) { return value; });
$youtubeVideo.on(youtubeVideoChanged, function (_, value) { return value; });
// Submited
sample({
    clock: submited,
    source: {
        phone: $phone,
        vk: $vk,
        instagram: $instagram,
        telegram: $telegram,
        youtube: $youtube,
        about: $about,
        youtube_video: $youtubeVideo,
    },
    target: updateFx,
});
sample({
    clock: updateFx.done,
    fn: function () { return 'Успешно сохранено!'; },
    target: $$alerts.showSuccess,
});
// Reset
sample({
    clock: $$myLanding.$item.updates,
    target: reseted,
});
sample({
    clock: reseted,
    source: $$myLanding.$item,
    filter: function (landing) { return !!landing; },
    target: spread({
        phone: $phone,
        vk: $vk,
        instagram: $instagram,
        telegram: $telegram,
        youtube: $youtube,
        about: $about,
        youtube_video: $youtubeVideo,
    }),
});
sample({
    clock: reseted,
    source: $$myLanding.$item,
    filter: function (landing) { return !landing; },
    fn: function () { return ''; },
    target: [$phone, $vk, $instagram, $telegram, $youtube, $about, $youtubeVideo],
});
export var $$editLandingForm = {
    $phone: $phone,
    $vk: $vk,
    $instagram: $instagram,
    $telegram: $telegram,
    $youtube: $youtube,
    $about: $about,
    $youtubeVideo: $youtubeVideo,
    $pending: $pending,
    phoneChanged: phoneChanged,
    vkChanged: vkChanged,
    instagramChanged: instagramChanged,
    telegramChanged: telegramChanged,
    youtubeChanged: youtubeChanged,
    aboutChanged: aboutChanged,
    youtubeVideoChanged: youtubeVideoChanged,
    submited: submited,
    reseted: reseted,
};
