var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { contact } from './contact';
import { FinanceRoute } from './finance';
import { MainRoute } from './main';
import { MarketplaceRoute } from './market-place';
import { ProfileRoute } from './profile';
import { strategy } from './strategy';
import { TeamRoute } from './team';
export var account = __spreadArray(__spreadArray(__spreadArray(__spreadArray([
    MainRoute,
    FinanceRoute,
    TeamRoute
], contact, true), [
    MarketplaceRoute
], false), strategy, true), [
    ProfileRoute,
], false);
