import { contact } from '@/shared/api';
import { $$session } from '@/shared/effector';
import { attach, createEvent, createStore, sample } from 'effector';
var getFx = attach({ effect: contact.get });
var $items = createStore([]);
var $length = $items.map(function (items) { return items.length; });
var $pending = getFx.pending;
var submited = createEvent();
// Authed
sample({
    clock: $$session.authed,
    target: submited,
});
// Submited
sample({
    clock: submited,
    target: getFx,
});
// Done
sample({
    clock: getFx.doneData,
    target: $items,
});
export var $$contacts = {
    $items: $items,
    $length: $length,
    $pending: $pending,
    submited: submited,
    done: getFx.done,
};
