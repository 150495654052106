import { contact } from '@/shared/api';
import { $$session } from '@/shared/effector';
import { attach, createEvent, createStore, sample } from 'effector';
var getLastFx = attach({ effect: contact.getLast });
var $item = createStore(null);
var $pending = getLastFx.pending;
var submited = createEvent();
// Authed
sample({
    clock: $$session.authed,
    target: submited,
});
// Submited
sample({
    clock: submited,
    target: getLastFx,
});
// Done
sample({
    clock: getLastFx.doneData,
    target: $item,
});
export var $$lastContact = {
    $item: $item,
    $pending: $pending,
    submited: submited,
    done: getLastFx.done,
};
