import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import './style.scss';
import { NoTableData, PageLayout } from '@/shared/components';
// import { $$balanceHistory } from '@/shared/effector'
import { useTableTheme } from '@/shared/hooks';
import { useTableSort } from '@/shared/hooks/use-table-sort';
import { myMoment } from '@/shared/tools';
import { Body, Cell, Header, HeaderCell, HeaderRow, Row, Table, } from '@table-library/react-table-library';
import { HeaderCellSort } from '@table-library/react-table-library/sort';
import classNames from 'classnames';
// import { useUnit } from 'effector-react'
import { memo } from 'react';
var mockItems = [
    {
        id: 1,
        sum: 100,
        type: 'Пополнение',
        login: 'hhrum',
        date: '2024-02-06T11:53:14.698Z',
    },
    {
        id: 2,
        sum: 1000,
        type: 'Пополнение',
        login: 'test',
        date: '2024-02-09T11:53:14.698Z',
    },
    {
        id: 3,
        sum: 10,
        type: 'Пополнение',
        login: 'hhrum',
        date: '2024-02-10T11:53:14.698Z',
    },
];
export var BalanceHistoryTable = function (_a) {
    var className = _a.className;
    // Effector
    // const history = useUnit($$balanceHistory.$items)
    var history = mockItems;
    var isEmpty = !history.length;
    var data = { nodes: history || [] };
    // Hooks
    var theme = useTableTheme({
        columns: {
            base: 'repeat(4, 25%)',
            tablet: 'repeat(4, 40%)',
            mobile: 'repeat(4, 70%)',
        },
        isNoData: isEmpty,
    });
    var sort = useTableSort(data, {
        SUM: function (items) { return items.sort(function (a, b) { return a.sum - b.sum; }); },
        TYPE: function (items) { return items.sort(function (a, b) { return a.type.localeCompare(b.type); }); },
        DATE: function (items) { return items.sort(function (a, b) { return +myMoment(b.date).isBefore(myMoment(a.date)); }); },
    });
    // Variables
    var ClassName = classNames('balance-history-table', className);
    return (_jsx(PageLayout.Cloud, { className: ClassName, header: { title: 'История баланса' }, children: _jsx(Table, { theme: theme, layout: { custom: true, horizontalScroll: true }, data: data, sort: sort, children: function (items) {
                return !isEmpty ? (_jsxs(_Fragment, { children: [_jsx(Header, { children: _jsxs(HeaderRow, { children: [_jsx(HeaderCellSort, { sortKey: "SUM", children: "\u0421\u0443\u043C\u043C\u0430 $" }), _jsx(HeaderCellSort, { sortKey: "TYPE", children: "\u0422\u0438\u043F" }), _jsx(HeaderCell, { children: "\u041E\u0442 \u043A\u043E\u0433\u043E" }), _jsx(HeaderCellSort, { sortKey: "DATE", children: "\u0414\u0430\u0442\u0430" })] }) }), _jsx(Body, { children: items.map(function (item) { return (_jsxs(Row, { className: "clickable-row", item: item, children: [_jsx(Cell, { children: item.sum }), _jsx(Cell, { children: item.type }), _jsx(Cell, { children: item.login }), _jsx(Cell, { children: myMoment(item.date).format(myMoment.defaultViewFormat) })] }, item.id)); }) })] })) : (_jsx(NoTableData, {}));
            } }) }));
};
export var MemoBalanceHistoryTable = memo(BalanceHistoryTable);
