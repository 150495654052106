var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MemoActivityPrm, MemoCopyInviteLinkButton } from '../shared';
import './style.scss';
import { ContactSidebarIcon, FinanceSidebarIcon, HomeSidebarIcon, LogoutSidebarIcon, MarketplaceSidebarIcon, ProfileSidebarIcon, StrategySidebarIcon, TeamSidebarIcon, } from '@/shared/assets';
import { Blob } from '@/shared/components/blob';
import { $$session } from '@/shared/effector';
import { useMediaQuery, useSidebar } from '@/shared/hooks';
import { routes } from '@/shared/router';
import { Link } from 'atomic-router-react';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { some } from 'patronum';
var navItems = [
    { icon: HomeSidebarIcon, to: routes.account.main, title: 'Главная' },
    { icon: FinanceSidebarIcon, to: routes.account.finance, title: 'Финансы' },
    { icon: TeamSidebarIcon, to: routes.account.team, title: 'Команда' },
    {
        icon: ContactSidebarIcon,
        to: routes.account.contact.items,
        title: 'Контакты',
        $isOpened: some({
            predicate: function (value) { return value; },
            stores: Object.values(routes.account.contact).map(function (route) { return route.$isOpened; }),
        }),
    },
    { icon: MarketplaceSidebarIcon, to: routes.account.marketplace, title: 'Маркетплейс' },
    {
        icon: StrategySidebarIcon,
        to: routes.account.strategy.items,
        title: 'Стратегии',
        $isOpened: some({
            predicate: function (value) { return value; },
            stores: Object.values(routes.account.strategy).map(function (route) { return route.$isOpened; }),
        }),
    },
    { icon: ProfileSidebarIcon, to: routes.account.profile, title: 'Профиль' },
];
var forCheckIsOpened = navItems.map(function (item) {
    return item.$isOpened ? item.$isOpened : item.to.$isOpened;
});
export var Sidebar = function () {
    // Effector
    var logout = useUnit($$session.logout);
    var isOpened = useUnit(forCheckIsOpened);
    // Hooks
    var _a = useSidebar(), isOpen = _a.isOpen, close = _a.close;
    var isLess425 = useMediaQuery('screen and (max-width: 425px)');
    // Variables
    var ClassName = classNames('sidebar', { 'sidebar--open': isOpen });
    var SpaceClassName = classNames('sidebar-space', { 'sidebar-space--open': isOpen });
    // Render
    var renderNavItem = function (_a, index) {
        var Icon = _a.icon, item = __rest(_a, ["icon"]);
        return (_jsxs(Link, { to: item.to, className: classNames('sidebar__nav-item sidebar-nav-item', isOpened[index] && 'active'), onClick: function () { return setTimeout(close, 200); }, children: [_jsx(Icon, { className: "sidebar-nav-item__icon" }), _jsx("div", { className: "sidebar-nav-item__title", children: item.title }), _jsx("div", { className: "sidebar-nav-item__status" })] }, index));
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: SpaceClassName, onClick: close }), _jsxs("div", { className: ClassName, children: [_jsx("a", { href: "/account", className: "sidebar__logo", children: _jsx("div", { className: "sidebar__logo-image" }) }), _jsx("div", { className: "sidebar__separator" }), _jsxs("div", { className: "sidebar__nav-items", children: [navItems.map(renderNavItem), _jsxs("div", { className: 'sidebar__nav-item sidebar-nav-item', onClick: logout, children: [_jsx(LogoutSidebarIcon, { className: "sidebar-nav-item__icon" }), _jsx("div", { className: "sidebar-nav-item__title", children: "\u0412\u044B\u0439\u0442\u0438" }), _jsx("div", { className: "sidebar-nav-item__status" })] }), _jsxs("div", { className: "sidebar__nav-space", children: [_jsx(MemoActivityPrm, { className: "sidebar__activity" }), _jsx(MemoCopyInviteLinkButton, {})] })] }), _jsx("div", { className: "sidebar__blob", children: _jsx(Blob, {}) })] })] }));
};
