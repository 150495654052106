import { jsx as _jsx } from "react/jsx-runtime";
import { PageLayout } from '@/shared/components';
import { useApexArea } from '@/shared/hooks';
import ReactApexChart from 'react-apexcharts';
var mockStats = [
    {
        name: 'Иван Иванов',
        values: [
            { label: 'SEP', value: 80 },
            { label: 'OCT', value: 40 },
            { label: 'NOV', value: 28 },
            { label: 'DEC', value: 52 },
            { label: 'JAN', value: 38 },
            { label: 'FEB', value: 100 },
        ],
    },
    {
        name: 'Александр Александров',
        values: [
            { label: 'SEP', value: 11 },
            { label: 'OCT', value: 32 },
            { label: 'NOV', value: 45 },
            { label: 'DEC', value: 32 },
            { label: 'JAN', value: 34 },
            { label: 'FEB', value: 52 },
        ],
    },
];
export var ContactsStats = function () {
    // Hooks
    var area = useApexArea(mockStats);
    return (_jsx(PageLayout.Cloud, { contentClassName: "contacts-stats", header: {
            title: 'Статистика контактов',
            subtitle: 'Показана статистика за последний месяц',
        }, children: _jsx(ReactApexChart, { type: "area", series: area.series, options: area.options, height: 260 }) }));
};
