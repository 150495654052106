import { request } from './request';
import { createEffect } from 'effector';
var getBalance = createEffect(function () {
    return request('finance/balance', { method: 'GET' });
});
var getPotentialIncome = createEffect(function () {
    return request('finance/potential_income', { method: 'GET' });
});
var getHistory = createEffect(function () {
    return request('finance/history', { method: 'GET' });
});
export var finance = {
    getBalance: getBalance,
    getPotentialIncome: getPotentialIncome,
    getHistory: getHistory,
};
