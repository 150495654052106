import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
export var ActivityPrm = function (_a) {
    var className = _a.className;
    // Variables
    var ClassName = classNames('activity-prm', className);
    return _jsx("div", { className: ClassName, children: "\u0410\u043A\u0442\u0438\u0432\u043D\u043E\u0441\u0442\u044C PRM: 100 \u0434\u043D\u0435\u0439" });
};
export var MemoActivityPrm = memo(ActivityPrm);
