import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SkeletonYourPotentialIncome } from './skeleton';
import './style.scss';
import { MemoActiveStrategySelect } from '@/features/strategy';
import { MemoButton, PageLayout } from '@/shared/components';
import { $$potentialIncome } from '@/shared/effector';
import { useSpace } from '@/shared/hooks';
import { useUnit } from 'effector-react';
export var YourPotentialIncome = function () {
    // Effector
    var _a = useUnit([$$potentialIncome.$value, $$potentialIncome.$pending]), value = _a[0], pending = _a[1];
    // Hooks
    var spacedValue = useSpace(value);
    // Render
    if (pending) {
        return _jsx(SkeletonYourPotentialIncome, {});
    }
    return (_jsxs(PageLayout.Cloud, { contentClassName: "your-potential-income", header: {
            title: 'Ваш потенциальный доход',
            subtitle: 'Покажи на что ты способен!',
            right: _jsx(MemoActiveStrategySelect, { className: "your-potential-income__select" }),
        }, children: [_jsxs("div", { className: "your-potential-income__text", children: [_jsxs("div", { className: "your-potential-income__value", children: ["$ ", spacedValue] }), _jsx("div", { className: "your-potential-income__title", children: "\u041F\u043E\u0442\u0435\u043D\u0446\u0438\u0430\u043B\u044C\u043D\u044B\u0439 \u0434\u043E\u0445\u043E\u0434" })] }), _jsx("div", { className: "your-potential-income__buttons", children: _jsx(MemoButton, { children: "\u041F\u043E\u043B\u043D\u044B\u0439 \u0440\u0430\u0441\u0447\u0435\u0442 \u043F\u043E\u0442\u0435\u043D\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0433\u043E \u0434\u043E\u0445\u043E\u0434\u0430" }) })] }));
};
