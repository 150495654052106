import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import { MemoActiveStrategySelect } from '@/features/strategy';
import { PageLayout } from '@/shared/components';
import { useApexFunnel } from '@/shared/hooks';
import { useFunnelStatuses } from '@/shared/hooks/strategy/use-funnel-statuses';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
export var SalesFunnel = function () {
    // Hooks
    var items = useFunnelStatuses({}).items;
    var funnel = useApexFunnel(items);
    // State
    var _a = useState([]), forceUpdate = _a[1];
    // Effects
    useEffect(function () {
        setTimeout(function () { return forceUpdate([]); }, 100);
    }, [items]);
    return (_jsx(PageLayout.Cloud, { contentClassName: "sales-funnel", header: {
            title: 'Воронка продаж',
            subtitle: 'Покажи на что ты способен!',
            right: _jsx(MemoActiveStrategySelect, { className: "sales-funnel__select" }),
        }, children: _jsx("div", { className: "sales-funnel__left", children: _jsx(ReactApexChart, { type: "bar", options: funnel.options, series: funnel.series, height: 260 }, items.length) }) }));
};
