import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SkeletonFinances } from './skeleton';
import './style.scss';
import { MemoButton, PageLayout } from '@/shared/components';
import { $$balance } from '@/shared/effector';
import { useSpace } from '@/shared/hooks';
import { useUnit } from 'effector-react';
export var Finances = function () {
    // Effector
    var _a = useUnit([$$balance.$value, $$balance.$pending]), value = _a[0], pending = _a[1];
    // Hooks
    var spacedValue = useSpace(value);
    // Render
    if (pending) {
        return _jsx(SkeletonFinances, {});
    }
    return (_jsxs(PageLayout.Cloud, { contentClassName: "finances", header: {
            title: 'Финансы',
            subtitle: 'Покажи на что ты способен!',
        }, children: [_jsxs("div", { className: "finances__text", children: [_jsxs("div", { className: "finances__value", children: ["$ ", spacedValue] }), _jsx("div", { className: "finances__title", children: "\u0412\u0430\u0448 \u0434\u043E\u0445\u043E\u0434" })] }), _jsx("div", { className: "finances__buttons", children: _jsx(MemoButton, { children: "\u0417\u0430\u044F\u0432\u043A\u0430 \u043D\u0430 \u0432\u044B\u0432\u043E\u0434" }) })] }));
};
