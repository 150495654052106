var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import './style.scss';
import { ImportContactsButton } from '@/features/contact';
import { MemoActiveStrategySelect } from '@/features/strategy';
import { MemoButton, NoTableData, PageLayout, SortButton } from '@/shared/components';
import { $$contactCount, $$contacts } from '@/shared/effector';
import { useTableTheme } from '@/shared/hooks';
import { routes } from '@/shared/router';
import { myMoment } from '@/shared/tools';
import { Body, Cell, Header, HeaderCell, HeaderRow, Row, Table, } from '@table-library/react-table-library';
import { HeaderCellSort, useSort } from '@table-library/react-table-library/sort';
import { useUnit } from 'effector-react';
function onSortChange() { }
export var ContactsTable = function () {
    // Effector
    var count = useUnit($$contactCount.$count);
    var contacts = useUnit($$contacts.$items);
    var isEmpty = !contacts.length;
    var data = {
        nodes: contacts.map(function (_a) {
            var contact_id = _a.contact_id, data = __rest(_a, ["contact_id"]);
            return (__assign({ id: contact_id }, data));
        }) || [],
    };
    // Hooks
    var theme = useTableTheme({
        columns: {
            base: 'repeat(8, 25%) auto',
            tablet: 'repeat(8, 40%) auto',
            mobile: 'repeat(8, 70%) auto',
        },
        isNoData: isEmpty,
    });
    var sort = useSort(data, {
        onChange: onSortChange,
    }, {
        sortIcon: {
            iconDefault: _jsx(SortButton, {}),
            iconUp: _jsx(SortButton, { sort: "ASC" }),
            iconDown: _jsx(SortButton, { sort: "DESC" }),
        },
        sortFns: {
            NAME: function (array) { return array.sort(function (a, b) { return a.first_name.localeCompare(b.first_name); }); },
        },
    });
    // Handlers
    var handleClick = function (item) {
        routes.account.contact.work.navigate({ params: { id: item.id }, query: {} });
    };
    var handleEdit = function (event, item) {
        event.stopPropagation();
        routes.account.contact.edit.navigate({ params: { contactId: item.id }, query: {} });
    };
    return (_jsx(PageLayout.Cloud, { headerClassName: "contacts-list-header", contentClassName: "contacts-list", header: {
            title: 'Контакты',
            subtitle: "\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u043E\u0432: ".concat(count),
            right: (_jsxs("div", { className: "contacts-list-header__buttons", children: [_jsx(MemoButton, { children: "\u042D\u043A\u0441\u043F\u043E\u0440\u0442" }), _jsx(ImportContactsButton, {}), _jsx(MemoActiveStrategySelect, {})] })),
        }, children: _jsx(Table, { theme: theme, layout: { custom: true, horizontalScroll: true }, data: data, sort: sort, children: function (items) {
                return !isEmpty ? (_jsxs(_Fragment, { children: [_jsx(Header, { children: _jsxs(HeaderRow, { children: [_jsx(HeaderCellSort, { sortKey: "NAME", children: "\u0424\u0418\u041E" }), _jsx(HeaderCell, { children: "\u0422\u0435\u043B\u0435\u0444\u043E\u043D" }), _jsx(HeaderCell, { children: "Telegram" }), _jsx(HeaderCell, { children: "\u041F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u044F" }), _jsx(HeaderCell, { children: "\u0414\u0430\u0442\u0430 \u0440\u043E\u0436\u0434\u0435\u043D\u0438\u044F" }), _jsx(HeaderCell, { children: "\u041F\u0440\u0438\u043C\u0435\u0447\u0430\u043D\u0438\u0435" }), _jsx(HeaderCell, { children: "\u0421\u0442\u0440\u0430\u043D\u0430" }), _jsx(HeaderCell, { children: "\u0413\u043E\u0440\u043E\u0434" }), _jsx(HeaderCell, { className: "edit-header-cell", pinRight: true, children: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C" })] }) }), _jsx(Body, { children: items.map(function (item) { return (_jsxs(Row, { className: "clickable-row", item: item, onClick: function () { return handleClick(item); }, children: [_jsxs(Cell, { children: [item.first_name, " ", item.last_name] }), _jsx(Cell, { children: item.phone }), _jsx(Cell, { children: item.telegram_ID }), _jsx(Cell, { children: item.profession }), _jsx(Cell, { children: myMoment(item.birth_date).format(myMoment.defaultViewFormat) }), _jsx(Cell, { children: item.comment }), _jsx(Cell, { children: item.country }), _jsx(Cell, { children: item.city }), _jsx(Cell, { className: "edit-cell", onClick: function (event) { return handleEdit(event, item); }, pinRight: true, children: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C" })] }, item.id)); }) })] })) : (_jsx(NoTableData, {}));
            } }) }));
};
