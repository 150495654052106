import { useMemo } from 'react';
export var useApexArea = function (items) {
    // Memo
    var config = useMemo(function () { return ({
        series: items.map(function (_a) {
            var name = _a.name, values = _a.values;
            return ({
                name: name,
                data: values.map(function (_a) {
                    var label = _a.label, value = _a.value;
                    return ({
                        x: label,
                        y: value,
                    });
                }),
            });
        }),
        options: {
            chart: {
                type: 'area',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            grid: {
                show: false,
            },
            legend: {
                show: false,
            },
            stroke: {
                curve: 'smooth',
            },
            tooltip: {
                x: {
                    show: false,
                },
            },
            xaxis: {
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                show: false,
            },
        },
    }); }, [items]);
    return config;
};
