var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { contactCustomStrategyStatus } from '@/shared/api';
import { $$activeStrategy } from '@/shared/effector';
import { RequestFactory } from '@/shared/factories';
import { createEvent, createStore, sample } from 'effector';
import { reset } from 'patronum';
var mapStatusToContactId = function (items) {
    return items.reduce(function (t, item) {
        var _a;
        return (__assign(__assign({}, t), (_a = {}, _a[item.contact_id.contact_id] = item.custom_strategy_status_id, _a)));
    }, {});
};
var updateOneItem = function (items, updatedItem) {
    var copied = __spreadArray([], items, true);
    var index = copied.findIndex(function (item) { return item.contact_id.contact_id === updatedItem.contact_id.contact_id; });
    if (index === -1) {
        return items;
    }
    copied[index] = updatedItem;
    return copied;
};
//* Consts
var LIMIT = 10;
//* Factories
var _a = RequestFactory({
    name: 'get-all CONTACT_CUSTOM_STRATEGY_STATUSES',
    api: contactCustomStrategyStatus.getAll,
}), requestSubmited = _a.submited, request = __rest(_a, ["submited"]);
var getOne = RequestFactory({
    name: 'get-one CONTACT_CUSTOM_STRATEGY_STATUSES',
    api: contactCustomStrategyStatus.getByContact,
});
//* Stores
var $limit = createStore(LIMIT);
var $offset = createStore(0);
var $items = createStore([]);
var $mapStatusToContactId = $items.map(function (items) { return mapStatusToContactId(items); });
var $countContactsInStatuses = $mapStatusToContactId.map(function (items) {
    var result = {};
    Object.keys(items).forEach(function (key) {
        var status_id = items[+key].custom_strategy_status_id;
        result[status_id] = (result[status_id] || 0) + 1;
    });
    return result;
});
//* Event
var oneUpdated = createEvent();
var reloaded = createEvent();
//* Logic
// Get All
reset({
    clock: [$$activeStrategy.activeStrategyChanged, reloaded],
    target: [$items, $offset],
});
sample({
    clock: [$$activeStrategy.activeStrategyChanged, reloaded],
    source: {
        strategy_id: $$activeStrategy.$activeStrategyId,
        limit: $limit,
        offset: $offset,
    },
    target: requestSubmited,
});
sample({
    clock: request.done,
    source: $items,
    fn: function (currentItems, response) { return __spreadArray(__spreadArray([], currentItems, true), response.items, true); },
    target: $items,
});
sample({
    clock: request.done,
    source: $offset,
    fn: function (offset, response) { return offset + response.items.length; },
    target: $offset,
});
sample({
    clock: request.done,
    source: {
        strategy_id: $$activeStrategy.$activeStrategyId,
        limit: $limit,
        offset: $offset,
    },
    filter: function (_a, response) {
        var limit = _a.limit;
        return response.items.length === limit;
    },
    target: requestSubmited,
});
// Get One
sample({
    clock: oneUpdated,
    source: $$activeStrategy.$activeStrategyId,
    fn: function (strategy_id, contact_id) { return ({ strategy_id: strategy_id, contact_id: contact_id }); },
    target: getOne.submited,
});
sample({
    clock: getOne.done,
    source: $items,
    fn: updateOneItem,
    target: $items,
});
export var $$contactCustomStrategyStatuses = __assign(__assign({}, request), { $items: $items, $mapStatusToContactId: $mapStatusToContactId, $countContactsInStatuses: $countContactsInStatuses, oneUpdated: oneUpdated, reloaded: reloaded });
