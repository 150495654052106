import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MemoActivityPrm, MemoCopyInviteLinkButton, PageTitle } from '../shared';
import './style.scss';
import { InfoHeaderIcon, MetamaskHeaderIcon, NotificationsHeaderIcon, SidebarToggle, ThemeHeaderIcon, } from '@/shared/assets';
import { Avatar } from '@/shared/components';
import { useSidebar } from '@/shared/hooks';
import { routes } from '@/shared/router';
import { useTheme } from '@/shared/theme';
import { Link } from 'atomic-router-react';
export var Header = function () {
    // Hooks
    var open = useSidebar().open;
    var toggle = useTheme().toggle;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "header-space", children: _jsx(PageTitle, { className: "header-space__title" }) }), _jsx("div", { className: "header", children: _jsxs("div", { className: "header__content", children: [_jsxs("div", { className: "header__left", children: [_jsx("div", { className: "header__sidebar-toggle", onClick: open, children: _jsx(SidebarToggle, { width: 36, height: 36 }) }), _jsx(PageTitle, { className: "header__title" }), _jsx(MemoActivityPrm, { className: "header__activity" })] }), _jsxs("div", { className: "header__right", children: [_jsx(MemoCopyInviteLinkButton, { className: "header__ref-link" }), _jsx("div", { className: "header__icon header-icon header-icon--metamask", children: _jsx("img", { src: MetamaskHeaderIcon }) }), _jsx("div", { className: "header__icon header-icon", children: _jsx(NotificationsHeaderIcon, {}) }), _jsx("div", { className: "header__icon header-icon header-icon--clickable", onClick: toggle, children: _jsx(ThemeHeaderIcon, {}) }), _jsx("div", { className: "header__icon header-icon", children: _jsx(InfoHeaderIcon, {}) }), _jsx(Link, { to: routes.account.profile, children: _jsx(Avatar, { className: "header__avatar" }) })] })] }) })] }));
};
