import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';
export var AlertItem = function (_a) {
    var id = _a.id, message = _a.message, variant = _a.variant, onClose = _a.onClose;
    // Handlers
    var handleClose = useCallback(function () { return onClose(id); }, [id, onClose]);
    return (_jsx(BootstrapAlert, { variant: variant, onClose: handleClose, dismissible: true, children: message }));
};
export var MemoAlertItem = memo(AlertItem);
