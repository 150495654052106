import { finance } from '@/shared/api';
import { $$session } from '@/shared/effector';
import { attach, createEvent, createStore, sample } from 'effector';
var getBalanceFx = attach({ effect: finance.getBalance });
var $value = createStore(0);
var $pending = getBalanceFx.pending;
var submited = createEvent();
// Authed
sample({
    clock: $$session.authed,
    target: submited,
});
// Submited
sample({
    clock: submited,
    source: $pending,
    filter: function (pending) { return !pending; },
    target: getBalanceFx,
});
// Done
sample({
    clock: getBalanceFx.doneData,
    fn: function (_a) {
        var balance = _a.balance;
        return balance;
    },
    target: $value,
});
export var $$balance = {
    $value: $value,
    $pending: $pending,
    submited: submited,
};
