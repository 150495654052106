import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { $$contactCommunication } from '../../model';
import { MemoOpenCommunicationModalButton } from '../open-communication-modal-button';
import './style.scss';
import { LevelUpContactButton } from '@/features/contact';
import { Form } from '@/shared/components';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { memo } from 'react';
export var ActiveStatusForm = function (_a) {
    var _b;
    var className = _a.className;
    // Effector
    var _c = useUnit([
        $$contactCommunication.info.$contactCustomStrategyStatus,
        $$contactCommunication.info.$nextStep,
    ]), contactCustomStrategyStatus = _c[0], step = _c[1];
    var pending = useUnit([$$contactCommunication.info.$infoPending])[0];
    // Variables
    var ClassName = classNames('communication-active-status-form', className);
    var nextStepExists = Boolean((_b = contactCustomStrategyStatus === null || contactCustomStrategyStatus === void 0 ? void 0 : contactCustomStrategyStatus.custom_strategy_status_id) === null || _b === void 0 ? void 0 : _b.custom_strategy_next_step_id);
    // Render
    if (!pending && !nextStepExists) {
        return (_jsx("div", { className: ClassName, children: _jsx("div", { className: "communication-active-status-form__note", children: "\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u0443 \u043F\u0440\u0438\u0441\u0432\u043E\u0435\u043D \u043A\u043E\u043D\u0435\u0447\u043D\u044B\u0439 \u0441\u0442\u0430\u0442\u0443\u0441 \u0432 \u0440\u0430\u043C\u043A\u0430\u0445 \u0432\u044B\u0431\u0440\u0430\u043D\u043D\u043E\u0439 \u0441\u0442\u0440\u0430\u0442\u0435\u0433\u0438\u0438" }) }));
    }
    return (_jsxs("div", { className: ClassName, children: [_jsx("div", { className: "communication-active-status-form__script", children: _jsx(Form.Input, { type: "textarea", label: "\u0421\u043A\u0440\u0438\u043F\u0442", disabled: true, rows: 4, value: (step === null || step === void 0 ? void 0 : step.description) || '' }) }), _jsxs("div", { className: "communication-active-status-form__buttons", children: [_jsx(MemoOpenCommunicationModalButton, {}), _jsx(LevelUpContactButton, {})] }), _jsx("div", { className: "communication-active-status-form__note", children: "\u041F\u0440\u0438\u043C\u0435\u0447\u0430\u043D\u0438\u0435: \u041D\u0443\u0436\u043D\u043E \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u043E\u0434\u043D\u043E\u0437\u043D\u0430\u0447\u043D\u044B\u0439 \u043F\u043E\u043B\u043E\u0436\u0438\u0442\u0435\u043B\u044C\u043D\u044B\u0439 \u043E\u0442\u0432\u0435\u0442. \u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435 \u0441\u043C\u043E\u0442\u0440\u0438\u0442\u0435 \u043D\u0430 \u0448\u043A\u043E\u043B\u0430\u0445 PRM." })] }));
};
export var MemoActiveStatusForm = memo(ActiveStatusForm);
