import { jsx as _jsx } from "react/jsx-runtime";
import BlobGIF from './blob.gif';
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
export var Blob = function (_a) {
    var className = _a.className;
    // Variables
    var ClassName = classNames('my-blob', className);
    return (_jsx("div", { className: ClassName, children: _jsx("img", { className: "my-blob__img", src: BlobGIF, alt: "blob" }) }));
};
export var MemoBlob = memo(Blob);
