import { finance } from '@/shared/api';
import { $$session } from '@/shared/effector';
import { attach, createEvent, createStore, sample } from 'effector';
var getPotentialIncomeFx = attach({ effect: finance.getPotentialIncome });
var $value = createStore(0);
var $pending = getPotentialIncomeFx.pending;
var submited = createEvent();
// Authed
sample({
    clock: $$session.authed,
    target: submited,
});
// Submited
sample({
    clock: submited,
    source: $pending,
    filter: function (pending) { return !pending; },
    target: getPotentialIncomeFx,
});
// Done
sample({
    clock: getPotentialIncomeFx.doneData,
    fn: function (_a) {
        var potentialIncome = _a.potentialIncome;
        return potentialIncome;
    },
    target: $value,
});
export var $$potentialIncome = {
    $value: $value,
    $pending: $pending,
    submited: submited,
};
