import { create } from './_create';
import { getAll } from './_get-all';
import { getCount } from './_get-count';
import { getItem } from './_get-item';
import { getLast } from './_get-last';
import { update } from './_update';
export var contact = {
    getCount: getCount,
    get: getAll,
    getLast: getLast,
    getItem: getItem,
    update: update,
    create: create,
};
export * from './types';
