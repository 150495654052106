import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import { useTheme } from '@/shared/theme';
import classNames from 'classnames';
import { memo } from 'react';
import ReactSpeedometer, { Transition } from 'react-d3-speedometer';
export var Speedometer = function (_a) {
    var className = _a.className, label = _a.label, items = _a.items, activeId = _a.activeId;
    // Hooks
    var theme = useTheme().theme;
    // Variables
    var ClassName = classNames('speedometer', className);
    var colors = items.map(function (_a) {
        var color = _a.color;
        return color;
    });
    var value = activeId !== null
        ? items.findIndex(function (_a) {
            var id = _a.id;
            return id === parseInt(activeId);
        })
        : null;
    return (_jsxs("div", { className: ClassName, children: [_jsx(ReactSpeedometer, { value: value !== null ? value + 0.2 : 0, minValue: 0, segmentColors: colors, maxValue: items.length, segments: items.length, textColor: "transparent", width: 265, height: 152, needleColor: theme === 'light' ? '#000' : '#FFF', needleHeightRatio: 0.8, needleTransitionDuration: 400, ringWidth: 15, needleTransition: Transition.easeLinear }, "".concat(theme, " ").concat(items.map(function (_a) {
                var color = _a.color;
                return color;
            }).join(' '))), label && _jsx("div", { className: "speedometer__label", children: label })] }));
};
export var MemoSpeedometer = memo(Speedometer);
